.compound {
	width: 100%;
	height: 100vh;
	position: relative;
	background: rgba(0, 0, 0, 0.3);
}
.compound:before {
	content: "";
	position: absolute;
	background: url("../../assets/chem-lab.png") no-repeat center center/cover;
	height: 100%;
	width: 100%;
	z-index: -1;
}

.compound .content {
	position: absolute;
	left: 50%;
	top: 30%;
	width: 50%;
	padding: 1rem;
}

.compound .content p {
	margin: 1rem 0;
	color: #f0f0f0;
	margin: 1rem 0;
}

@media screen and (max-width: 940px) {
	.compound .content {
		left: 0;
		width: 100%;
		padding: 1rem;
		background: rgba(0, 0, 0, 0.15);
		box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
		backdrop-filter: blur(4px);
	}
}
