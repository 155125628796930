.retail {
	width: 100%;
	height: 100vh;
	position: relative;
	background: rgba(0, 0, 0, 0.3);
}
.retail:before {
	content: "";
	position: absolute;
	background: url("../../assets/retail.png") no-repeat center center/cover;
	height: 100%;
	width: 100%;
	z-index: -1;
}

.retail .content {
	position: absolute;
	left: 50%;
	top: 30%;
	width: 50%;
	padding: 1rem;
}

.retail .content p {
	margin: 1rem 0;
	color: #f0f0f0;
	margin: 1rem 0;
}

.logo {
	/* font-size: 3rem; */
	margin-right: 1rem;
	height: 100%;
	width: 20vw;
	padding: 5px;
	cursor: pointer;
	margin-left: 5%;
	/* color: var(--primary-color); */
}

@media screen and (max-width: 940px) {
	.retail .content {
		left: 0;
		width: 100%;
		padding: 1rem;
		background: rgba(0, 0, 0, 0.15);
		box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
		backdrop-filter: blur(4px);
	}
}
