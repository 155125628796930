.navbar {
	width: 100%;
	height: 100px;
	position: absolute;
	z-index: 10;
}
.navbar .container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.navbar .logo {
	display: flex;
	align-items: center;
	color: #fff;
	margin: 1rem;
	margin-top: 1rem;
	width: 30%;
}

/* .navbar .logo .icon {
	font-size: 3rem;
	margin-right: 1rem;
	height: 90%;
	width: 5vw;
	padding: 5px;
	cursor: pointer;
	/* color: var(--primary-color); 
}
*/

.hamburger {
	display: none;
	padding: 1rem;
}

.navbar .nav-menu {
	display: flex;
	padding: 1rem;
}

.navbar li {
	padding: 1rem;
	font-weight: 600;
	color: #fff;
}

.navbar .icon {
	font-size: 1.4rem;
	color: #fff;
}

@media screen and (max-width: 940px) {
	.navbar .hamburger {
		display: block;
		z-index: 15;
	}
	.navbar .nav-menu {
		position: absolute;
		flex-direction: column;
		width: 100%;
		height: 100vh;
		top: 0;
		left: -100%;
		background: rgba(0, 0, 0, 0.8);
		align-items: center;
		justify-content: center;
		transition: 0.5s ease-in;
	}

	.navbar .active {
		left: 0;
	}

	.navbar li {
		margin-right: 1rem;
		font-size: 2rem;
	}
}
