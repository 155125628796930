.policy {
	width: 100%;
	height: 100vh;
	position: relative;
	background: rgba(0, 0, 0, 0.5);
}

.policy:before {
	content: "";
	position: absolute;
	background: url("../../assets/data-bg.png") no-repeat center center/cover;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.policy .container {
	top: 12%;
	width: 100%;
	padding: 1rem;
	overflow-x: scroll;
	justify-content: left;
	align-items: center;
	text-align: left;
	background: rgba(0, 0, 0, 0.15);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(4px);
}
.policy .content {
	padding: 1rem;
	margin-bottom: 5rem;
}
.policy h1 {
	margin: 1rem;
	margin-bottom: 0.8rem;
	color: #f0f0f0;
	font-size: 1.5rem;
}
.policy .content p {
	margin: 0.1rem 0;
	color: #f0f0f0;
	font-size: 0.9rem;
}
.policy .content strong {
	margin: 0.1rem 0;
	color: var(--primary-color);
	font-size: 0.9rem;
}
.policy .content li {
	margin: 0.1rem 0;
	color: #f0f0f0;
	font-size: 0.9rem;
}
