.data {
	width: 100%;
	height: 100vh;
	position: relative;
	background: rgba(0, 0, 0, 0.1);
}

.data:before {
	content: "";
	position: absolute;
	background: url("../../assets/vaccine-covid.png") no-repeat center
		center/cover;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.data .content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	top: 30%;
	width: 90%;
	background: rgba(0, 0, 0, 0.15);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(4px);
	padding: 1rem;
}
.data h2 {
	color: #f8f8f8;
	font-size: 2.7rem;
}
.data .content p {
	margin: 1rem 0;
	color: white;
}

@media screen and (max-width: 940px) {
	.data .content {
		left: 0;
		width: 100%;
		padding: 1rem;
	}
}
