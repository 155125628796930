.terms {
	width: 100%;
	height: 100vh;
	position: relative;
	background: rgba(0, 0, 0, 0.4);
}
.terms:before {
	content: "";
	position: absolute;
	background: url("../../assets/legal-banner.png") no-repeat center center/cover;
	height: 100%;
	width: 100%;
	z-index: -1;
}
.terms .container {
	top: 14%;
	left: 6%;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	margin: 0;
	padding: 1rem;
	overflow-x: scroll;
	background: rgba(0, 0, 0, 0.15);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(4px);
}
.terms h1 {
	color: #f8f8f8;
}
.terms .text {
	width: 100%;
	margin: 0.5rem;
	color: #f8f8f8;
	margin-bottom: 12rem;
}
.terms .blue {
	color: var(--primary-color);
}

@media screen and (max-width: 940px) {
	.terms .container {
		margin-left: 1rem;
		justify-content: left;
		align-items: left;
		left: 0;
	}
}
