.address {
	width: 100%;
	height: 100vh;
	position: relative;
	background: rgba(0, 0, 0, 0.5);
}

.address:before {
	content: "";
	position: absolute;
	background: url("../../assets/Location-background.png") no-repeat center
		center/cover;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.address .container {
	position: absolute;
	left: 10%;
	top: 25%;
	width: 50%;
	padding: 1rem;
}
.address h1 {
	margin: 1.5rem;
	color: #f0f0f0;
}
.address .content p {
	margin: 0.1rem 0;
	color: #f0f0f0;
	font-size: 1.5rem;
}

@media screen and (max-width: 940px) {
	.address .content {
		left: 0;
		width: 100%;
		padding: 1rem;
	}
	.address .content p {
		margin: 0rem 0;
		font-size: 1rem;
	}
}
